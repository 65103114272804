import { render, staticRenderFns } from "./StoreSupportGroup.vue?vue&type=template&id=fac51740&scoped=true&"
import script from "./StoreSupportGroup.vue?vue&type=script&lang=js&"
export * from "./StoreSupportGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fac51740",
  null
  
)

export default component.exports