<template>
  <div v-loading="loaders.supportGroup">
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Edit Support Group</h1>
      <support-group-form
        @updateSupportGroup="updateSupportGroup"
        :support-group-data-to-edit="response.data"
      />
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import SupportGroupForm from "@/views/Pages/ContentSection/SupportGroup/SupportGroup/SupportGroupForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "EditSupportGroup",
  components: {
    BackButton, SupportGroupForm
  },
  data () {
    return {
      loaders: {
        supportGroup: false
      },
      response: {
        data: []
      },
      supportGroupId: this.$route.params.id
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Fetch the relevant support group data
     */
    fetchSupportGroup() {
      let vm = this
      vm.loaders.supportGroup = true
      const payload = {
        id: vm.supportGroupId
      }
      vm.$store.dispatch('_getSupportGroup', payload)
      .then(response => {
        vm.response.data = response.data.data
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Group',
          message: message
        })
      })
      vm.loaders.supportGroup = false
    },

    /**
     * Update the support group
     * @param {event} event - Event Object
     */
    updateSupportGroup(event) {
      let vm = this
      vm.loaders.supportGroup = true
      var formData = new FormData()
      formData.append('id', this.supportGroupId)
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = {headers: {'content-type': 'multipart/form-data'}}
      vm.$store.dispatch('_updateSupportGroup', {config, formData})
      .then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Support Group updated successfully. Always check to see how your changes look in the Baritastic app.'
        })
        vm.$router.back()
      })
      .catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Group',
          dangerouslyUseHTMLString: true,
          message: message
        })
      })
      .finally(() => {
        vm.loaders.supportGroup = false
      })
    },

  },
  created() {
    this.fetchSupportGroup()
  }
}
</script>

<style scoped>

</style>
