<template>
  <div v-loading="loaders.resource">
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1>Add Support Group</h1>
      <support-group-form
        @storeSupportGroup="storeSupportGroup"
      />
    </div>
  </div>
</template>

<script>

import BackButton from "@/components/Router/BackButton"
import SupportGroupForm from "@/views/Pages/ContentSection/SupportGroup/SupportGroup/SupportGroupForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "StoreSupportGroup",
  components: {
    BackButton, SupportGroupForm
  },
  data() {
    return {
      loaders: {
        resource: false
      }
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {

    /**
     * Store a new support group
     * @param event - Event Object
     */
    storeSupportGroup(event) {
      let vm = this
      vm.loaders.resource = true
      var formData = new FormData()
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = {headers: {'content-type': 'multipart/form-data'}}
      vm.$store.dispatch('_storeSupportGroup', {config, formData})
      .then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Support Group created successfully.'
        })
        vm.$router.back()
      })
      .catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Group',
          dangerouslyUseHTMLString: true,
          message: message
        })
      })
      .finally(() => {
        vm.loaders.resource = false
      })
    },

  }
}
</script>

<style scoped>

</style>
