<template>
  <div>
    <div class="ml-4 pl-3">
      <back-button/>
    </div>
    <div class="container-fluid d-flex justify-content-between mt-4 mb-4 row">
      <h1 class="ml-3 d-inline-block mt-2">Support Group View</h1>
      <router-link class="router-link pr-2" :to="{name: 'EditSupportGroup' , params: { id: supportGroupId } }">
        <base-button type="secondary" class="pt-2 pb-2 font-weight-normal custom-button">
          Edit
        </base-button>
      </router-link>
    </div>
    <div class="mt-1 row px-3 mx-0">
      <div class="col-6 content-section-card-width">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2"
              id="basic-info-card" v-loading="loaders.supportGroup">
          <div slot="header" class="d-flex ">
            <img class="rounded image-fit" v-if="response.supportGroup.image" :src="response.supportGroup.image" width="124" height="114" alt=""/>
            <div class="content-section-header-width">
              <div class="row justify-content-between ml-2 mr-0">
                <div class="d-inline-block content-section-heading-width">
                  <h1 class="support-name">{{ response.supportGroup.title }}</h1>
                </div>
                <div class="d-inline-block">
                  <p class="support-status px-3 px-1 py-1 mt-1"
                     :class="response.supportGroup.status === 0 ? 'inactive' : 'active'">
                    {{ getStatus(response.supportGroup.status) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 class="mt-3 pl-4 support-detail-heading">BASIC INFO</h2>
          <div class="row pt-2 px-4">
            <div class="col-6">
              <p class="support-detail-text">Support Group Category</p>
              <p class="support-detail-text">Support Group Sub Category</p>
            </div>
            <div class="col-6">
              <p class="black_p ellipsis-overflow-text" v-if="response.supportGroup.support_group_category">
                {{ response.supportGroup.support_group_category.name }}
              </p>
              <p class="black_p ellipsis-overflow-text" v-if="response.supportGroup.support_group_sub_category">
                {{ response.supportGroup.support_group_sub_category.name }}
              </p>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6 content-section-card-width">
        <card class="overflow-auto no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loaders.supportGroup">
          <div class="px-4">
            <h2 class="mt-2 support-detail-heading">Content</h2>
            <p class="mb-2 overflow-auto support-group-max-height" v-html="response.supportGroup.content"></p>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import BackButton from "@/components/Router/BackButton";

export default {
  name: "ShowSupportGroup",
  components: {
    BackButton
  },
  data() {
    return {
      supportGroupId: this.$route.params.id,
      loaders: {
        supportGroup: false
      },
      response: {
        supportGroup: ''
      }
    }
  },
  methods: {
    /**
     * Get the Support Group base on ID and set response data
     */
    async getSupportGroup() {
      let vm = this
      vm.loaders.supportGroup = true
      const payload = {
        id: vm.supportGroupId
      }
      try {
        const response = await vm.$store.dispatch('_getSupportGroup', payload)
        vm.response.supportGroup = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Support Group',
          message: message
        })
      }
      vm.loaders.supportGroup = false
    },

    /**
     * Get Status in number and returns its Value in String
     * @param {number} status - Status of the Support Group
     * @returns {string}
     */
    getStatus(status) {
      return (status) ? 'Active' : 'Inactive'
    },
  },
  mounted() {
    this.getSupportGroup()
  }
}
</script>

<style scoped>

.image-fit{
  object-fit: cover;
}

.support-name {
  text-align: left;
  font: normal normal 600 25px/34px Open Sans;
  letter-spacing: 0;
  color: #32325D;
  opacity: 1;
}

.support-status {
  box-shadow: 0px 3px 2px #E9ECEF0D;
  border-radius: 20px;
  opacity: 1;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0.48px;
  color: #FFFFFF;
}

.active {
  background: #94B527 0 0 no-repeat padding-box;
}

.inactive {
  background: #BB2255 0 0 no-repeat padding-box;
}

.support-detail-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}

.support-detail-text {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  opacity: 1;
}

.support-group-max-height{
  max-height: 400px;
}

</style>
