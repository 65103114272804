<template>
  <div class="forms mt-4" v-loading="loaders">
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
        <card type="default" header-classes="bg-transparent">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <base-input
                :rules="{required: true}"
                name="Name"
                label="Support Group Name*"
                v-model="request.supportGroupForm.title"
                placeholder="Name">
              </base-input>
            </div>
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Select Support Group Category</label>
              <el-select
                class="w-100"
                v-model="request.supportGroupForm.category"
                placeholder="Select Category"
                @change="onChangeSupportGroupCategory"
              >
                <el-option
                  class="select-danger"
                  :value="0"
                  label="Select Category"
                  key="0">
                </el-option>
                <el-option
                  v-for="option in response.categories"
                  class="select-danger max-width-730"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Select Support Group Sub Category</label>
              <el-select
                class="w-100"
                v-model="request.supportGroupForm.subCategory"
                placeholder="Select Sub Category"
              >
                <el-option
                  class="select-danger"
                  :value="0"
                  label="Select Sub Category"
                  key="0">
                </el-option>
                <el-option
                  v-for="option in response.subCategories"
                  class="select-danger max-width-730"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id">
                </el-option>
              </el-select>
            </div>
            <div class="col-lg-6 col-md-6">
              <base-input label="Status*" :rules="{required: true}">
                <el-select
                  class="w-100"
                  label="Status"
                  v-model="request.supportGroupForm.status">
                  <el-option
                    v-for="option in dropdowns.status"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-6 row m-0 mb-4">
              <label class="form-control-label w-100">PDF</label>
              <div class="col-md-6 pl-0 pr-2">
                <div class="radio-button-card pl-3">
                  <base-radio name="v1" v-model="selectedValue" value="pdfValues.r1.value" class="pt-3 base-radio-button" selected>
                    <span class="base-radio-text">Yes</span>
                  </base-radio>
                </div>
              </div>
              <div class="col-md-6 pr-0 pl-2">
                <div class="radio-button-card pl-3">
                  <base-radio name="v2" v-model="selectedValue" value="pdfValues.r2.value" class="pt-3 base-radio-button">
                    <span class="base-radio-text">No</span>
                  </base-radio>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center" v-if="selectedValue=='v2'">
              <div class="img-upload" :class="{'w-100':!imageExist}">
                <label class="form-control-label" for="profileImage">Picture</label>
                <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                       class="form-control file-upload" @change="onImageChange">
                <span class="font-size-12px">Recommended image size 500x300 px. Must be less than 500kb.</span>
              </div>
              <el-image
                v-loading="request.loaders.image"
                class="img-size pl-2"
                :class="{'d-none':!imageExist}"
                :src="request.supportGroupForm.oldImage"
                @load="handleLoad"
                @error="handleLoad"
              />
            </div>
            <div class="col-lg-6 col-md-6" v-if="selectedValue=='v1'">
              <label class="form-control-label" for="PDF">PDF</label>
              <input type="file" id="pdf" ref="pdf" accept="application/pdf" class="form-control file-upload"
                     @change="onFileChange">
              <span class="font-size-12px">File size should be 20Mb or less.</span>
            </div>
          </div>
          <div class="row mt-4" v-if="selectedValue=='v2'">
            <div class="col equal-height-columns">
              <label class="form-control-label">Content</label>
              <editor
                v-model="request.supportGroupForm.content"
                :api-key=editorApiKey
                :init=editorOption
              />
            </div>
          </div>
        </card>
      </form>
      <base-button
        @click="onSubmit('supportGroupForm')"
        class="btn-primary-action mb-4 br-4 pr-5 pl-5"
        native-type="submit"
        type="submit">
        Save
      </base-button>
    </validation-observer>
  </div>
</template>

<script>

import {store} from "@/vuex/store";
import Helper from "@/util/globalHelpers";

export default {
  name: "SupportGroupForm",
  props: ['supportGroupDataToEdit', 'loaders'],
  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      request: {
        loaders: {
          image: false,
        },
        supportGroupForm: {
          title: '',
          category: 0,
          subCategory: 0,
          oldImage: '',
          image: '',
          pdf_name: '',
          pdfFile: '',
          is_pdf: 0,
          status: 1,
          content: '',
        },
      },
      response: {
        categories: [],
        subCategories: [],
      },
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      pdfValues: {
        v1: {value: 1, label: 'Yes'},
        v2: {value: 0, label: 'No'},
      },
      selectedValue: 'v2',
    }
  },
  mounted() {
    this.getAllSupportGroupCategories()
  },
  methods: {
    /**
     * Save the pdf file into support group data
     * @param {event} e - Event object
     */
    onFileChange(e) {
      if (e.target.files[0].size > 20000000) {
        this.$notify.error({
          title: 'Error',
          message: 'The pdf file size can not be greater than 20Mb.'
        })
        this.$refs.pdf.value = null
        return
      }
      this.request.supportGroupForm.pdfFile = e.target.files[0];
    },

    /**
     * Handle the change of Support Group Category
     * @param {number} supportGroupCategory - Support Group Category
     */
    onChangeSupportGroupCategory(supportGroupCategory) {
      if (supportGroupCategory == 0) {
        this.response.subCategories = []
        this.request.supportGroupForm.subCategory = 0
      } else {
        this.fetchSupportGroupSubCategories(supportGroupCategory)
        this.request.supportGroupForm.subCategory = 0
      }
    },

    /**
     * Fetch relevant support group sub categories
     * @param {number} id - Id of the Support Group Category
     */
    fetchSupportGroupSubCategories(id) {
      let vm = this;
      if (id) {
        const payload = {id}
        vm.$store.dispatch('_fetchRelevantSupportGroupSubCategories', payload)
          .then(response => {
            vm.response.subCategories = response.data.data
          })
          .catch(error => {
            let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
            vm.$notify.error({
              title: 'Support Group',
              message: message
            })
          })
      }
    },

    /**
     * Fetch all the support group categories
     */
    getAllSupportGroupCategories() {
      let vm = this
      vm.$store.dispatch('_getAllSupportGroupCategories')
        .then(response => {
          vm.response.categories = response.data.data
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Support Group',
            message: message
          })
        })
    },

    /**
     * Redirect router and emit the relevant function
     * @param {string} formName - Form name
     */
    async onSubmit(formName) {
      let vm = this
      const isSupportGroupFormValid = await this.$refs['formValidator'].validate();
      if (isSupportGroupFormValid) {
        vm.request.supportGroupForm.content = Helper.addCustomStylingToContent(vm.request.supportGroupForm.content)
        vm.request.supportGroupForm.is_pdf = (vm.selectedValue === 'v1') ? 1 : 0
        if (this.$route.name === 'StoreSupportGroup') {
          vm.$emit('storeSupportGroup', vm.request.supportGroupForm);
        } else if (this.$route.name === 'EditSupportGroup') {
          vm.$emit('updateSupportGroup', vm.request.supportGroupForm);
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },

    /**
     * Save the image file into support group data
     * @param {event} e - Event object
     */
    onImageChange(e) {
      this.request.supportGroupForm.image = e.target.files[0];
    },

    /**
     * Handles the image load
     */
    handleLoad() {
      this.request.loaders.image = false
    },

    /**
     * Assigning new values to supportGroupDataToEdit property
     * @param newVal - New value of supportGroupDataToEdit
     */
    assignSupportGroupProp(newVal) {
      this.request.supportGroupForm.title = newVal.title ?? ''
      this.request.supportGroupForm.category = newVal.support_group_category_id ?? 0
      this.request.supportGroupForm.subCategory = newVal.support_group_main_category_id ?? 0
      this.request.supportGroupForm.oldImage = newVal.image ?? ''
      this.request.supportGroupForm.is_pdf = newVal.is_pdf ?? ''
      this.request.supportGroupForm.pdf_name = newVal.pdf_name ?? ''
      this.request.supportGroupForm.status = newVal.status ?? ''
      this.request.supportGroupForm.content = newVal.content ?? ''
      this.selectedValue = newVal.is_pdf === 1 ? 'v1' : 'v2'
      if (newVal.hasOwnProperty('support_group_category')) {
        this.fetchSupportGroupSubCategories(newVal.support_group_category_id);
      }
    }
  },
  watch: {
    /**
     * Save new value to supportGroupDataToEdit property
     * @param newVal - New value of supportGroupDataToEdit property
     */
    supportGroupDataToEdit(newVal) {
      this.assignSupportGroupProp(newVal)
    }
  },
  computed: {
    /**
     * Computed property to check picture if exist already
     * @returns {false|string|boolean}
     */
    imageExist() {
      return this.request.supportGroupForm.oldImage !== ''
        && this.request.supportGroupForm.oldImage
        && this.request.supportGroupForm.image === ''
    }
  }
}
</script>

<style scoped>

.max-width-730 {
  max-width: 730px;
}

.img-size {
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload {
  width: calc(100% - 120px);
  display: inline-block;
}

.base-radio-text {
  font: normal normal normal 14px/19px Open Sans;
  color: #525F7F;
}

.form-control.file-upload {
  height: calc(1.5em + 1.25rem + 13px);
  padding-top: calc(0.75rem + 3px);
}

.radio-button-card {
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}
</style>
